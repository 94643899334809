import { FIAT_SYNTHS } from '@dextoroprotocol/sdk/constants'
import Wei from '@synthetixio/wei'
import keyBy from 'lodash/keyBy'

export type CurrencyKey = string

// TODO: standardize this
export type Category = 'crypto' | 'forex' | 'equities' | 'index' | 'commodity' | 'inverse'

export const CATEGORY: Category[] = ['crypto', 'forex', 'equities', 'index', 'commodity', 'inverse']
export const CATEGORY_MAP = keyBy(CATEGORY)

export const LSE_SYNTHS = new Set<CurrencyKey>([])

export const TSE_SYNTHS = new Set<CurrencyKey>([])

export const AFTER_HOURS_SYNTHS: Set<CurrencyKey> = new Set([])

export const MARKET_HOURS_SYNTHS = new Set([
	...FIAT_SYNTHS,
	...LSE_SYNTHS,
	...TSE_SYNTHS,
	...AFTER_HOURS_SYNTHS,
])

// Commodity synths are not listed in the CurrencyKey currently. This is a temporary workaround.
export const COMMODITY_SYNTHS = new Set<CurrencyKey | 'XAU' | 'XAG' | 'WTI'>(['XAU', 'XAG', 'WTI'])

export const INDEX_SYNTHS = new Set<CurrencyKey | 'DebtRatio'>(['DebtRatio'])

export const sUSD_EXCHANGE_RATE = new Wei(1)
export const SYNTH_DECIMALS = 18

export enum SynthsName {
	sUSD = 'sUSD',
	sETH = 'sETH',
	sBTC = 'sBTC',
	sLINK = 'sLINK',
	sSOL = 'sSOL',
	sAVAX = 'sAVAX',
	sPOL = 'sPOL',
	sEUR = 'sEUR',
	sAAVE = 'sAAVE',
	sUNI = 'sUNI',
	sINR = 'sINR',
	sJPY = 'sJPY',
	sGBP = 'sGBP',
	sCHF = 'sCHF',
	sKRW = 'sKRW',
	sADA = 'sADA',
	sAUD = 'sAUD',
	sDOT = 'sDOT',
	sETHBTC = 'sETHBTC',
	sSTETHETH = 'sSTETHETH',
	sXMR = 'sXMR',
	sOP = 'sOP',
}

export const CurrencyName: Record<string, string> = {
	sBTCPERP: 'Bitcoin',
	sYFIPERP: 'Yearn',
	sSTETHPERP: 'Lido Staked Ether',
	sBNBPERP: 'Binance Coin',
	sSTETHETHPERP: 'stETHETH',
	sETHPERP: 'Ethereum',
	sXAUPERP: 'Gold',
	sMKRPERP: 'Maker',
	sBCHPERP: 'Bitcoin Cash',
	sXMRPERP: 'Monero',
	sTRBPERP: 'Tellor',
	sAAVEPERP: 'Aave',
	sSOLPERP: 'Solana',
	sLTCPERP: 'Litecoin',
	sCOMPPERP: 'Compound',
	sGMXPERP: 'GMX',
	sINJPERP: 'Injective',
	sAVAXPERP: 'Avalanche',
	sRPLPERP: 'Rocket Pool',
	sETCPERP: 'Ethereum Classic',
	sZECPERP: 'Zcash',
	sXAGPERP: 'Silver',
	sTIAPERP: 'Celestia',
	sLINKPERP: 'Chainlink',
	sICPPERP: 'Internet Computer',
	sATOMPERP: 'Cosmos',
	sAPTPERP: 'Aptos',
	sFXSPERP: 'Frax Share',
	sAXSPERP: 'Axie Infinity',
	sDOTPERP: 'Polkadot',
	sUNIPERP: 'Uniswap',
	sFILPERP: 'Filecoin',
	sRUNEPERP: 'THORChain',
	sBALPERP: 'Balancer',
	sRENDERPERP: 'Render',
	sOPPERP: 'Optimism',
	sLDOPERP: 'Lido',
	sNEARPERP: 'Near',
	sDYDXPERP: 'DYDX',
	sWLDPERP: 'Worldcoin',
	sARBPERP: 'Arbitrum',
	sIMXPERP: 'Immutable',
	sUMAPERP: 'UMA',
	sAPEPERP: 'ApeCoin',
	sPERPPERP: 'Perpetual Protocol',
	sGBPPERP: 'GBP/USD',
	sSUIPERP: 'Sui',
	sXTZPERP: 'Tezos',
	sSUSHIPERP: 'Sushi',
	sEURPERP: 'EUR/USD',
	sUSDTPERP: 'Tether',
	sPOLPERP: 'Polygon',
	sFLOWPERP: 'Flow',
	sSEIPERP: 'Sei',
	sEOSPERP: 'EOS',
	sCELOPERP: 'Celo',
	sBLURPERP: 'Blur',
	sAUDPERP: 'AUD/USD',
	sKNCPERP: 'Kyber Network',
	sMAVPERP: 'Maverick',
	sXRPPERP: 'XRP',
	sCRVPERP: 'Curve DAO',
	sADAPERP: 'Cardano',
	s1INCHPERP: '1inch',
	sPYTHPERP: 'Pyth Network',
	sENJPERP: 'Enjin',
	sALGOPERP: 'Algorand',
	sGRTPERP: 'The Graph',
	sXLMPERP: 'Stellar',
	sTRXPERP: 'Tron',
	sDOGEPERP: 'Dogecoin',
	sETHBTCPERP: 'Ether/Bitcoin',
	sMEMEPERP: 'Memecoin',
	sANKRPERP: 'Ankr Network',
	sZILPERP: 'Zilliqa',
	sONEPERP: 'One',
	sFLOKIPERP: 'Floki',
	sBONKPERP: 'Bonk',
	sSHIBPERP: 'Shiba Inu',
	sPEPEPERP: 'Pepe',
	sJTOPERP: 'Jito',
	sORDIPERP: 'Ordi',
	sCVXPERP: 'Convex Finance',
	sJUPPERP: 'Jupiter',
	sPENDLEPERP: 'Pendle',
	sSTRKPERP: 'Starknet',
}

export const CustomisedTickers: Record<string, string> = {
	sAUDPERP: 'AUD/USD',
	sGBPPERP: 'GBP/USD',
	sEURPERP: 'EUR/USD',
}
